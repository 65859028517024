<template>
    <div>
        <stripe-checkout
                ref="checkoutRef"
                mode="payment"
                :pk="publishableKey"
                :line-items="lineItems"
                :success-url="successURL"
                :cancel-url="cancelURL"
                @loading="v => loading = v"
        />
    </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';

export default {
    components: {
        StripeCheckout,
    },
    props: ['isRequested'],
    watch: {
        isRequested: function (n, o){
            if(n){
                this.submit();
            }
        }
    },
    data () {
        return {
            loading: false,
            publishableKey: this.$PUBLISHABLE_KEY,
            lineItems: [
                {
                    price: this.$PRICE_ID, // The id of the one-time price you created in your Stripe dashboard
                    quantity: 1,
                },
            ],
            successURL: this.$SITE_URI+ '/payment/success',
            cancelURL: this.$SITE_URI+ '/payment/failed',
        };
    },
    methods: {
        submit () {
            // You will be redirected to Stripe's secure checkout page
            this.$refs.checkoutRef.redirectToCheckout();
        },
    },
};
</script>